// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  ArrayField,
  ArrayInput,
  SimpleFormIterator,
  ReferenceField,
  ReferenceInput,
  BooleanField,
  BooleanInput,
  NumberField,
  NumberInput,
  ImageField,
  TextField,
  TextInput,
  DateField,
  DateInput,
  DateTimeInput,
  RichTextField,
  AutocompleteInput,
  SelectInput,
  FileField,
  FileInput,
  UrlField,
  Datagrid,
  SingleFieldList,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

// Constants {{{
const ratingChoices = [
  { id: 1, name: "Good" },
  { id: 2, name: "Okay" },
  { id: 3, name: "Bad" },
];

const prefectureChoices = [
  { id: "北海道", name: "北海道" },
  { id: "青森県", name: "青森県" },
  { id: "岩手県", name: "岩手県" },
  { id: "宮城県", name: "宮城県" },
  { id: "秋田県", name: "秋田県" },
  { id: "山形県", name: "山形県" },
  { id: "福島県", name: "福島県" },
  { id: "茨城県", name: "茨城県" },
  { id: "栃木県", name: "栃木県" },
  { id: "群馬県", name: "群馬県" },
  { id: "埼玉県", name: "埼玉県" },
  { id: "千葉県", name: "千葉県" },
  { id: "東京都", name: "東京都" },
  { id: "神奈川県", name: "神奈川県" },
  { id: "新潟県", name: "新潟県" },
  { id: "富山県", name: "富山県" },
  { id: "石川県", name: "石川県" },
  { id: "福井県", name: "福井県" },
  { id: "山梨県", name: "山梨県" },
  { id: "長野県", name: "長野県" },
  { id: "岐阜県", name: "岐阜県" },
  { id: "静岡県", name: "静岡県" },
  { id: "愛知県", name: "愛知県" },
  { id: "三重県", name: "三重県" },
  { id: "滋賀県", name: "滋賀県" },
  { id: "奈良県", name: "奈良県" },
  { id: "和歌山県", name: "和歌山県" },
  { id: "京都府", name: "京都府" },
  { id: "大阪府", name: "大阪府" },
  { id: "兵庫県", name: "兵庫県" },
  { id: "鳥取県", name: "鳥取県" },
  { id: "島根県", name: "島根県" },
  { id: "岡山県", name: "岡山県" },
  { id: "広島県", name: "広島県" },
  { id: "山口県", name: "山口県" },
  { id: "徳島県", name: "徳島県" },
  { id: "香川県", name: "香川県" },
  { id: "愛媛県", name: "愛媛県" },
  { id: "高知県", name: "高知県" },
  { id: "福岡県", name: "福岡県" },
  { id: "佐賀県", name: "佐賀県" },
  { id: "長崎県", name: "長崎県" },
  { id: "熊本県", name: "熊本県" },
  { id: "大分県", name: "大分県" },
  { id: "宮崎県", name: "宮崎県" },
  { id: "鹿児島県", name: "鹿児島県" },
  { id: "沖縄県", name: "沖縄県" },
];

const genderChoices = [
  { id: "男", name: "男" },
  { id: "女", name: "女" },
  { id: "その他", name: "その他" },
];

const permissionChoices = [
  { id: "0", name: "Villager" },
  { id: "1", name: "Hero" },
  { id: "2", name: "Knight" },
  { id: "99", name: "Admin" },
];
const categoryChoices = [
  { id: "起業", name: "起業" },
  { id: "ビジネスモデル", name: "ビジネスモデル" },
  { id: "AI", name: "AI" },
  { id: "マーケティング", name: "マーケティング" },
  { id: "ビジネス英語", name: "ビジネス英語" },
  { id: "組織設計", name: "組織設計" },
  { id: "ブランディング", name: "ブランディング" },
  { id: "ファイナンス", name: "ファイナンス" },
  { id: "市場調査", name: "市場調査" },
  { id: "IPO", name: "IPO" },
  { id: "スタンフォード流", name: "スタンフォード流" },
  { id: "動画編集", name: "動画編集" },
  { id: "デザインセールス", name: "デザインセールス" },
  { id: "インサイドセールス", name: "インサイドセールス" },
  { id: "資金調達", name: "資金調達" },
  { id: "ピッチ資料", name: "ピッチ資料" },
  { id: "プログラミング", name: "プログラミング" },
  { id: "IR・PR戦略", name: "IR・PR戦略" },
  { id: "リモートワーク", name: "リモートワーク" },
  { id: "ストックオプション", name: "ストックオプション" },
  { id: "グローバル戦略", name: "グローバル戦略" },
  { id: "経営管理", name: "経営管理" },
  { id: "採用戦略", name: "採用戦略" },
];

export const attributeChoices = [
  { id: "経営者", name: "経営者" },
  { id: "役員", name: "役員" },
  { id: "正社員", name: "正社員" },
  { id: "パート・アルバイト", name: "パート・アルバイト" },
  { id: "自営業", name: "自営業" },
  { id: "学生", name: "学生" },
  { id: "無職", name: "無職" },
  { id: "派遣社員", name: "派遣社員" },
  { id: "投資家", name: "投資家" },
];

// }}}

// -------- Posts {{{
export const post = {
  attributes: {
    title: {
      read: <TextField source="title" />,
      write: <TextInput source="title" />,
    },
    body: {
      read: <TextField source="body" />,
      write: <TextInput source="body" />,
    },
    createdate: {
      read: <TextField source="createdate" />,
      write: <TextInput source="createdate" disabled />,
    },
    comments: {
      write: (
        <ReferenceInput label="Comment" source="title" reference="comments">
          <SelectInput optionText="title" />
        </ReferenceInput>
      ),
      read: (
        <ReferenceField label="Comment" source="title" reference="comments">
          <TextField source="title" />
        </ReferenceField>
      ),
    },
  },
  list: ["title", "body", "createdate"],
  show: ["title", "body", "createdate", "comments"],
  create: ["title", "body", "comments"],
  edit: ["title", "body", "createdate", "comments"],
};
// }}}

// -------- Comments  {{{
export const comments = {
  attributes: {
    title: {
      read: <TextField source="title" />,
      write: <TextInput source="title" />,
    },
    body: {
      read: <RichTextField source="body" />,
      write: <RichTextInput source="body" />,
    },
    createdate: {
      read: <TextField source="createdate" />,
      write: <TextInput source="createdate" />,
    },
    rating: {
      write: <SelectInput source="rating" choices={ratingChoices} />,
      read: <TextField source="rating" />,
    },
  },
  list: ["title", "body", "createdate"],
  show: ["title", "body", "createdate", "rating"],
  create: ["title", "body", "createdate"],
  edit: ["title", "body", "createdate", "rating"],
};

// }}}

// ======== Account Stuff
// -------- Accounts {{{
export const account = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput disabled source="id" />,
    },
    agreement: {
      read: <BooleanField source="agreement" />,
      write: <BooleanInput source="agreement" />,
    },
    email: {
      read: <TextField source="email" />,
      write: <TextInput disabled source="email" />,
    },
    stripe_id: {
      read: <TextField source="stripe_id" />,
      write: <TextInput disabled source="stripe_id" />,
    },
    subscription_id: {
      read: <TextField source="subscription_id" />,
      write: <TextInput disabled source="subscription_id" />,
    },
  },
  list: ["id", "email", "stripe_id"],
  show: ["id", "email", "agreement", "stripe_id", "subscription_id"],
  edit: ["id", "email", "agreement", "stripe_id", "subscription_id"],
};
// }}}

// -------- Users {{{
export const user = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput disabled source="id" />,
    },
    given_name: {
      read: <TextField source="given_name" />,
      write: <TextInput source="given_name" />,
    },
    given_name_kana: {
      read: <TextField source="given_name_kana" />,
      write: <TextInput source="given_name_kana" />,
    },
    family_name: {
      read: <TextField source="family_name" />,
      write: <TextInput source="family_name" />,
    },
    family_name_kana: {
      read: <TextField source="family_name_kana" />,
      write: <TextInput source="family_name_kana" />,
    },
    avatar: {
      read: <ImageField source="avatar.src" title="data.title" />,
      write: (
        <FileInput source="avatar">
          <ImageField source="src" title="title" />
        </FileInput>
      ),
    },
    area: {
      write: <SelectInput source="area" choices={prefectureChoices} />,
      read: <TextField source="area" />,
    },
    attribute: {
      write: <SelectInput source="attribute" choices={attributeChoices} />,
      read: <TextField source="attribute" />,
    },
    // industry: {
    //   write: <SelectInput source="industry" choices={industryChoices} />,
    //   read: <TextField source="industry" />,
    // },
    // TODO add interests with array
    // TODO add sns_data with json
    // TODO add contacts with json
    introduction: {
      read: <TextField source="introduction" />,
      write: <TextInput source="introduction" />,
    },
    gender: {
      write: <SelectInput source="gender" choices={genderChoices} />,
      read: <TextField source="gender" />,
    },
    birthdate: {
      read: <DateField source="birthdate" />,
      write: <DateInput source="birthdate" />,
    },
  },
  list: ["family_name", "given_name", "area", "attribute"],
  show: [
    "id",
    "avatar",
    "family_name",
    "given_name",
    "family_name_kana",
    "given_name_kana",
    "area",
    "attribute",
    "introduction",
    "birthdate",
  ],
  edit: [
    "id",
    "avatar",
    "family_name",
    "given_name",
    "family_name_kana",
    "given_name_kana",
    "area",
    "attribute",
    "introduction",
    "birthdate",
  ],
};

// }}}

// -------- Profiles {{{
export const profile = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput disabled source="id" />,
    },
    family_name: {
      read: <TextField source="family_name" />,
      write: <TextInput source="family_name" />,
    },
    family_name_kana: {
      read: <TextField source="family_name_kana" />,
      write: <TextInput source="family_name_kana" />,
    },
    given_name: {
      read: <TextField source="given_name" />,
      write: <TextInput source="given_name" />,
    },
    given_name_kana: {
      read: <TextField source="given_name_kana" />,
      write: <TextInput source="given_name_kana" />,
    },
    email: {
      read: <TextField source="email" />,
      write: <TextInput source="email" disabled />,
    },
    birthdate: {
      read: <DateField source="birthdate" />,
      write: <DateInput source="birthdate" />,
    },
    last_viewed_lesson_id: {
      read: <TextField source="last_viewed_lesson_id" />,
      write: <TextInput source="last_viewed_lesson_id" />,
    },
    subscribed: {
      read: <BooleanField source="subscribed" />,
      write: <BooleanInput source="subscribed" />,
    },
    setup: {
      read: <BooleanField source="setup" />,
      write: <BooleanInput source="setup" />,
    },
  },
  list: ["family_name", "given_name", "email"],
  show: [
    "id",
    "family_name",
    "given_name",
    "family_name_kana",
    "given_name_kana",
    "email",
    "birthdate",
    "last_viewed_lesson_id",
    "subscribed",
    "setup",
  ],
  create: [
    "id",
    "family_name",
    "given_name",
    "family_name_kana",
    "given_name_kana",
    "email",
    "birthdate",
    "subscribed",
    "setup",
  ],
  edit: [
    "id",
    "family_name",
    "given_name",
    "family_name_kana",
    "given_name_kana",
    "email",
    "birthdate",
    "subscribed",
    "setup",
  ],
};
// }}}

// ======== Lesson Stuff
// -------- Lessons {{{
export const lesson = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput disabled source="id" />,
    },
    subject_id: {
      read: (
        <ReferenceField source="subject_id" reference="subjects">
          <TextField source="title" />
        </ReferenceField>
      ),
      write: (
        <ReferenceInput source="subject_id" reference="subjects">
          <SelectInput optionText="title" />
        </ReferenceInput>
      ),
    },
    thumbnail: {
      read: <ImageField source="thumbnail.src" title="data.title" />,
      write: (
        <FileInput source="thumbnail">
          <ImageField source="src" title="title" />
        </FileInput>
      ),
    },
    topic: {
      read: (
        <ReferenceField source="topic" reference="topics">
          <TextField source="name" />
        </ReferenceField>
      ),
      write: (
        <ReferenceInput source="topic" reference="topics">
          <SelectInput source="name" />
        </ReferenceInput>
      ),
    },
    problems: {
      read: (
        <ArrayInput source="problems">
          <Datagrid>
            <TextInput source="question" />
            <NumberInput source="correct" />
            <ArrayInput source="choices">
              <SingleFieldList>
                <TextInput />
              </SingleFieldList>
            </ArrayInput>
          </Datagrid>
        </ArrayInput>
      ),
      write: (
        <ArrayInput source="problems">
          <SimpleFormIterator>
            <TextInput source="question" />
            <NumberInput source="correct" />
            <ArrayInput source="choices">
              <SimpleFormIterator>
                <TextInput />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      ),
    },
    youtube_id: {
      read: <TextField source="youtube_id" />,
      write: <TextInput source="youtube_id" />,
    },
    title: {
      read: <TextField source="title" />,
      write: <TextInput source="title" />,
    },
    description: {
      read: <RichTextField source="description" multiline />,
      write: <RichTextInput source="description" multiline />,
    },
    permission_level: {
      write: (
        <SelectInput source="permission_level" choices={permissionChoices} />
      ),
      read: <TextField source="permission_level" />,
    },
    created_at: {
      read: <DateField source="created_at" showTime />,
      write: <TextInput source="created_at" disabled />,
    },
    updated_at: {
      read: <DateField source="updated_at" showTime />,
      write: <TextInput source="updated_at" disabled />,
    },
  },
  list: ["title", "topic", "subject_id"],
  show: [
    "thumbnail",
    "title",
    "description",
    "subject_id",
    "topic",
    "youtube_id",
  ],
  create: [
    "thumbnail",
    "title",
    "description",
    "subject_id",
    "topic",
    "problems",
    "youtube_id",
  ],
  edit: [
    "thumbnail",
    "title",
    "description",
    "subject_id",
    "topic",
    "problems",
    "youtube_id",
  ],
};
// }}}
// -------- VoiceMedia {{{
export const voice_media = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput disabled source="id" />,
    },
    thumbnail: {
      read: <ImageField source="thumbnail.src" title="data.title" />,
      write: (
        <FileInput source="thumbnail">
          <ImageField source="src" title="title" />
        </FileInput>
      ),
    },
    topic: {
      read: (
        <ReferenceField source="topic" reference="topics">
          <TextField source="name" />
        </ReferenceField>
      ),
      write: (
        <ReferenceInput source="topic" reference="topics">
          <SelectInput source="name" />
        </ReferenceInput>
      ),
    },
    youtube_id: {
      read: <TextField source="youtube_id" />,
      write: <TextInput source="youtube_id" />,
    },
    title: {
      read: <TextField source="title" />,
      write: <TextInput source="title" />,
    },
    description: {
      read: <RichTextField source="description" multiline />,
      write: <RichTextInput source="description" multiline />,
    },
    permission_level: {
      write: (
        <SelectInput source="permission_level" choices={permissionChoices} />
      ),
      read: <TextField source="permission_level" />,
    },
    created_at: {
      read: <DateField source="created_at" showTime />,
      write: <TextInput source="created_at" disabled />,
    },
    updated_at: {
      read: <DateField source="updated_at" showTime />,
      write: <TextInput source="updated_at" disabled />,
    },
  },
  list: ["title", "topic"],
  show: ["thumbnail", "title", "description", "topic", "youtube_id"],
  create: ["thumbnail", "title", "description", "topic", "youtube_id"],
  edit: ["thumbnail", "title", "description", "topic", "youtube_id"],
};
// }}}

// ======== Class Stuff
// -------- Categories
export const category = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput source="id" />,
    },
    name: {
      read: <TextField source="name" />,
      write: <TextInput source="name" />,
    },
    thumbnail: {
      read: <ImageField source="thumbnail.src" title="data.title" />,
      write: (
        <FileInput source="thumbnail">
          <ImageField source="src" title="title" />
        </FileInput>
      ),
    },
    created_at: {
      read: <DateField source="created_at" showTime />,
      write: <TextInput source="created_at" disabled />,
    },
    updated_at: {
      read: <DateField source="updated_at" showTime />,
      write: <TextInput source="updated_at" disabled />,
    },
  },
  list: ["id", "name"],
  show: ["id", "name"],
  create: ["id", "name", "thumbnail"],
  edit: ["id", "name", "thumbnail"],
};

// -------- Topics
export const topic = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput source="id" />,
    },
    name: {
      read: <TextField source="name" />,
      write: <TextInput source="name" />,
    },
    created_at: {
      read: <DateField source="created_at" showTime />,
      write: <TextInput source="created_at" disabled />,
    },
    updated_at: {
      read: <DateField source="updated_at" showTime />,
      write: <TextInput source="updated_at" disabled />,
    },
  },
  list: ["id", "name"],
  show: ["id", "name"],
  create: ["id", "name"],
  edit: ["id", "name"],
};

// -------- Subject {{{
export const subject = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput disabled source="id" />,
    },
    thumbnail: {
      read: <ImageField source="thumbnail.src" title="data.title" />,
      write: (
        <FileInput source="thumbnail">
          <ImageField source="src" title="title" />
        </FileInput>
      ),
    },
    title: {
      read: <TextField source="title" />,
      write: <TextInput source="title" />,
    },
    description: {
      read: <TextField source="description" />,
      write: <TextInput source="description" />,
    },
    category: {
      read: (
        <ReferenceField
          label="category"
          source="category"
          reference="categories"
        >
          <TextField source="name" />
        </ReferenceField>
      ),
      write: (
        <ReferenceInput
          label="category"
          source="category"
          reference="categories"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
      ),
    },
    instructor: {
      read: (
        <ReferenceField
          label="instructor"
          source="instructor_id"
          reference="instructors"
        >
          <TextField source="full_name" />
        </ReferenceField>
      ),
      write: (
        <ReferenceInput
          label="instructor"
          source="instructor_id"
          reference="instructors"
        >
          <SelectInput optionText="full_name" />
        </ReferenceInput>
      ),
    },
    lesson_ids: {
      read: (
        <ArrayField source="lesson_ids">
          <Datagrid>
            <NumberField source="index" />
            <ReferenceField source="id" reference="lessons">
              <TextField source="title" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      ),
      write: (
        <ArrayInput source="lesson_ids">
          <SimpleFormIterator>
            <NumberInput source="index" />
            <ReferenceInput
              source="id"
              reference="lessons"
              perPage={10000}
              sort={{ field: "title", order: "ASC" }}
            >
              <SelectInput optionText="title" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      ),
    },
    created_at: {
      read: <DateField source="created_at" showTime />,
      write: <TextInput source="created_at" disabled />,
    },
    updated_at: {
      read: <DateField source="updated_at" showTime />,
      write: <TextInput source="updated_at" disabled />,
    },
  },
  list: ["title", "instructor", "category"],
  show: [
    "title",
    "description",
    "instructor",
    "category",
    "lesson_ids",
    "thumbnail",
  ],
  create: [
    "title",
    "description",
    "instructor",
    "category",
    "lesson_ids",
    "thumbnail",
  ],
  edit: [
    "title",
    "description",
    "instructor",
    "category",
    "lesson_ids",
    "thumbnail",
  ],
};
// }}}

// -------- ClassSubscriptions {{{
export const subject_subscription = {
  attributes: {
    id: {
      read: (
        <ReferenceField title="id" reference="profiles" source="id">
          <TextField source="email" />
        </ReferenceField>
      ),
      write: (
        <ReferenceInput title="id" reference="profiles" source="id">
          <SelectInput optionText="email" />
        </ReferenceInput>
      ),
    },
    subjects: {
      read: (
        <ArrayField source="subjects">
          <Datagrid>
            <NumberField source="index" />
            <ReferenceField source="id" reference="subjects">
              <TextField source="title" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      ),
      write: (
        <ArrayInput source="subjects">
          <SimpleFormIterator>
            <NumberInput source="index" />
            <ReferenceInput source="id" reference="subjects">
              <SelectInput optionText="title" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      ),
    },
    completed: {
      read: <BooleanField source="completed" />,
      write: <BooleanInput source="completed" />,
    },
    created_at: {
      read: <DateField source="created_at" showTime />,
      write: <TextInput source="created_at" disabled />,
    },
    updated_at: {
      read: <DateField source="updated_at" showTime />,
      write: <TextInput source="updated_at" disabled />,
    },
  },
  list: ["id", "completed"],
  show: ["id", "subjects", "completed", "updated_at", "created_at"],
  create: ["id", "subjects", "completed"],
  edit: ["id", "subjects", "completed", "updated_at", "created_at"],
};
// }}}

// -------- Instructors {{{
export const instructor = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput disabled source="id" />,
    },
    full_name: {
      read: <TextField source="full_name" />,
      write: <TextInput source="full_name" />,
    },
    full_name_kana: {
      read: <TextField source="full_name_kana" />,
      write: <TextInput source="full_name_kana" />,
    },
    category: {
      read: (
        <ReferenceField
          label="category"
          source="category"
          reference="categories"
        >
          <TextField source="name" />
        </ReferenceField>
      ),
      write: (
        <ReferenceInput
          label="category"
          source="category"
          reference="categories"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
      ),
    },
    description: {
      read: <RichTextField source="description" multiline />,
      write: <RichTextInput source="description" multiline />,
    },
    exeternal_link: {
      read: <UrlField source="exeternal_link" />,
      write: <TextInput source="exeternal_link" type="url" />,
    },
    avatar: {
      read: <ImageField source="avatar.src" title="data.title" />,
      write: (
        <FileInput source="avatar">
          <ImageField source="src" title="title" />
        </FileInput>
      ),
    },
    created_at: {
      read: <DateField source="created_at" showTime />,
      write: <TextInput source="created_at" disabled />,
    },
    updated_at: {
      read: <DateField source="updated_at" showTime />,
      write: <TextInput source="updated_at" disabled />,
    },
  },
  list: ["full_name", "category", "exeternal_link"],
  show: [
    "avatar",
    "full_name",
    "full_name_kana",
    "category",
    "description",
    "exeternal_link",
  ],
  create: [
    "avatar",
    "full_name",
    "full_name_kana",
    "category",
    "description",
    "exeternal_link",
  ],
  edit: [
    "avatar",
    "full_name",
    "full_name_kana",
    "category",
    "description",
    "exeternal_link",
  ],
};
// }}}

// -------- Announcements {{{
export const announcement = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput disabled source="id" />,
    },
    title: {
      read: <TextField source="title" />,
      write: <TextInput source="title" />,
    },
    description: {
      read: <RichTextField source="description" multiline />,
      write: <RichTextInput source="description" multiline />,
    },
    external_link: {
      read: <UrlField source="external_link" />,
      write: <TextInput source="external_link" type="url" />,
    },
    created_at: {
      read: <DateField source="created_at" showTime />,
      write: <TextInput source="created_at" disabled />,
    },
    updated_at: {
      read: <DateField source="updated_at" showTime />,
      write: <TextInput source="updated_at" disabled />,
    },
  },
  list: ["title", "created_at"],
  show: ["title", "description", "created_at", "external_link"],
  create: ["title", "description", "external_link"],
  edit: ["title", "description", "external_link"],
};
// -------- LiveStreamEvents
export const live_stream_event = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput disabled source="id" />,
    },
    thumbnail: {
      read: <ImageField source="thumbnail.src" title="data.title" />,
      write: (
        <FileInput source="thumbnail">
          <ImageField source="src" title="title" />
        </FileInput>
      ),
    },
    title: {
      read: <TextField source="title" />,
      write: <TextInput source="title" />,
    },
    description: {
      read: <RichTextField source="description" multiline />,
      write: <RichTextInput source="description" multiline />,
    },
    datetime: {
      read: <DateField source="datetime" showTime />,
      write: <DateTimeInput type="datetime-local" source="datetime" />,
    },
    created_at: {
      read: <DateField source="created_at" />,
      write: <TextInput source="created_at" disabled />,
    },
    updated_at: {
      read: <DateField source="updated_at" showTime />,
      write: <TextInput source="updated_at" disabled />,
    },
    youtube_id: {
      read: <TextField source="youtube_id" />,
      write: <TextInput source="youtube_id" />,
    },
  },
  list: ["title", "created_at", "datetime", "youtube_id"],
  show: [
    "title",
    "description",
    "datetime",
    "youtube_id",
    "created_at",
    "thumbnail",
  ],
  create: ["title", "description", "datetime", "youtube_id", "thumbnail"],
  edit: ["title", "description", "datetime", "youtube_id", "thumbnail"],
};

// -------- Events
export const event = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput disabled source="id" />,
    },
    thumbnail: {
      read: <ImageField source="thumbnail.src" title="data.title" />,
      write: (
        <FileInput source="thumbnail">
          <ImageField source="src" title="title" />
        </FileInput>
      ),
    },
    title: {
      read: <TextField source="title" />,
      write: <TextInput source="title" />,
    },
    description: {
      read: <RichTextField source="description" multiline />,
      write: <RichTextInput source="description" multiline />,
    },
    datetime: {
      read: <DateField source="datetime" showTime />,
      write: <DateTimeInput type="datetime-local" source="datetime" />,
    },
    created_at: {
      read: <DateField source="created_at" />,
      write: <TextInput source="created_at" disabled />,
    },
    updated_at: {
      read: <DateField source="updated_at" showTime />,
      write: <TextInput source="updated_at" disabled />,
    },
    facebook_url: {
      read: <UrlField source="facebook_url" />,
      write: <TextInput source="facebook_url" type="url" />,
    },
    google_map_url: {
      read: <UrlField source="google_map_url" />,
      write: <TextInput source="google_map_url" type="url" />,
    },
    detail_url: {
        read: <UrlField source="detail_url" />,
        write: <TextInput source="detail_url" type="url" />,
    }
  },
  list: ["title", "id", "created_at", "datetime", "facebook_url"],
  show: [
    "title",
    "id",
    "description",
    "datetime",
    "created_at",
    "thumbnail",
    "facebook_url",
    "google_map_url",
    "detail_url",
  ],
  create: [
    "title",
    "description",
    "datetime",
    "thumbnail",
    "facebook_url",
    "google_map_url",
    "detail_url",
  ],
  edit: [
    "title",
    "description",
    "datetime",
    "thumbnail",
    "facebook_url",
    "google_map_url",
    "detail_url",
  ],
};
// }}}

// -------- News {{{
export const news = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput disabled source="id" />,
    },
    title: {
      read: <TextField source="title" />,
      write: <TextInput source="title" />,
    },
    description: {
      read: <RichTextField source="description" multiline />,
      write: <RichTextInput source="description" multiline />,
    },
    created_at: {
      read: <DateField source="created_at" />,
      write: <TextInput source="created_at" disabled />,
    },
    image: {
      read: <ImageField source="image.src" title="image.title" />,
      write: (
        <FileInput source="image">
          <FileField source="src" title="title" />
        </FileInput>
      ),
    },
  },
  list: ["title", "created_at"],
  show: ["title", "description", "image", "created_at"],
  create: ["title", "description", "image"],
  edit: ["title", "description", "image"],
};
// }}}

// --------- AdminControls {{{
export const admin_controls = {
  attributes: {
    id: {
      read: <TextField source="id" />,
      write: <TextInput disabled source="id" />,
    },
    highlighted_events: {
      read: (
        <ArrayField source="highlighted_events">
          <Datagrid>
            <NumberField source="index" />
            <ReferenceField source="id" reference="events">
              <TextField source="title" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      ),
      write: (
        <ArrayInput source="highlighted_events">
          <SimpleFormIterator>
            <NumberInput source="index" />
            <ReferenceInput source="id" reference="events">
              <SelectInput optionText="title" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      ),
    },
    highlighted_live_streams: {
      read: (
        <ArrayField source="highlighted_live_streams">
          <Datagrid>
            <NumberField source="index" />
            <ReferenceField source="id" reference="live_stream_events">
              <TextField source="title" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      ),
      write: (
        <ArrayInput source="highlighted_live_streams">
          <SimpleFormIterator>
            <NumberInput source="index" />
            <ReferenceInput source="id" reference="live_stream_events">
              <SelectInput optionText="title" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      ),
    },
    highlighted_voice_medias: {
      read: (
        <ArrayField source="highlighted_voice_medias">
          <Datagrid>
            <NumberField source="index" />
            <ReferenceField source="id" reference="lessons">
              <TextField source="title" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      ),
      write: (
        <ArrayInput source="highlighted_voice_medias">
          <SimpleFormIterator>
            <NumberInput source="index" />
            <ReferenceInput source="id" reference="lessons">
              <SelectInput optionText="title" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      ),
    },
  },
  list: ["id"],
  show: [
    "id",
    "highlighted_events",
    "highlighted_live_streams",
    "highlighted_voice_medias",
  ],
  edit: [
    "id",
    "highlighted_events",
    "highlighted_live_streams",
    "highlighted_voice_medias",
  ],
};

// }}}
