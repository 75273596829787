// in src/voice_medias.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
} from "react-admin";
import { voice_media } from "./models";

const VoiceMediaFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="title" alwaysOn />
  </Filter>
);

export const VoiceMediaList = (props) => (
  <List {...props} filters={<VoiceMediaFilter />}>
    <Datagrid>
      {voice_media.list.map((item) => {
        return voice_media.attributes[item]["read"];
      })}
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const VoiceMediaShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      {voice_media.show.map((item) => {
        return voice_media.attributes[item].read;
      })}
    </SimpleShowLayout>
  </Show>
);

export const VoiceMediaCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {voice_media.create.map((item) => {
        return voice_media.attributes[item].write;
      })}
    </SimpleForm>
  </Create>
);

export const VoiceMediaEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {voice_media.edit.map((item) => {
        return voice_media.attributes[item].write;
      })}
    </SimpleForm>
  </Edit>
);
