import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextInput,
  ShowButton,
  EditButton,
} from "react-admin";
import { admin_controls } from "./models";

const AdminControlsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="id" alwaysOn />
  </Filter>
);

export const AdminControlsList = (props) => (
  <List {...props} filters={<AdminControlsFilter />}>
    <Datagrid>
      {admin_controls.list.map((item) => {
        return admin_controls.attributes[item]["read"];
      })}
      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export const AdminControlsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      {admin_controls.show.map((item) => {
        return admin_controls.attributes[item].read;
      })}
    </SimpleShowLayout>
  </Show>
);

export const AdminControlsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {admin_controls.edit.map((item) => {
        return admin_controls.attributes[item].write;
      })}
    </SimpleForm>
  </Edit>
);
