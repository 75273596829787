import * as React from "react";
import japaneseMessages from "@bicstone/ra-language-japanese";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { AccountList, AccountShow, AccountEdit } from "./objects/accounts";
import { ProfileList, ProfileShow, ProfileEdit } from "./objects/profiles";
import {
  InstrcutorList,
  InstrcutorCreate,
  InstrcutorShow,
  InstrcutorEdit,
} from "./objects/instructors";
import { UserList, UserShow, UserEdit } from "./objects/users";
import {
  AdminControlsList,
  AdminControlsShow,
  AdminControlsEdit,
} from "./objects/admin_controls";
import {
  LessonList,
  LessonShow,
  LessonCreate,
  LessonEdit,
} from "./objects/lessons";
import {
  VoiceMediaList,
  VoiceMediaShow,
  VoiceMediaCreate,
  VoiceMediaEdit,
} from "./objects/voice_media";
import {
  SubjectList,
  SubjectShow,
  SubjectCreate,
  SubjectEdit,
} from "./objects/subjects";
// import {
//   SubjectSubscriptionList,
//   SubjectSubscriptionShow,
//   SubjectSubscriptionCreate,
//   SubjectSubscriptionEdit,
// } from "./objects/subject_subscriptions";
import {
  LiveStreamEventList,
  LiveStreamEventShow,
  LiveStreamEventCreate,
  LiveStreamEventEdit,
} from "./objects/live_stream_event";
import { EventList, EventShow, EventCreate, EventEdit } from "./objects/events";
import {
  CategoryList,
  CategoryShow,
  CategoryCreate,
  CategoryEdit,
} from "./objects/categories";
import { NewsList, NewsShow, NewsCreate, NewsEdit } from "./objects/news";
import {
  AnnouncementList,
  AnnouncementShow,
  AnnouncementCreate,
  AnnouncementEdit,
} from "./objects/announcements";
import { TopicList, TopicShow, TopicCreate, TopicEdit } from "./objects/topics";
import { Admin, Resource } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from "react-admin-firebase";
import CustomLoginPage from "./CustomLoginPage";

import { firebaseConfig as config } from "./FIREBASE_CONFIG";
import {
  People,
  Person,
  Assignment,
  LiveTv,
  Announcement,
  DynamicFeed,
  Class,
  Folder,
  Palette,
} from "@material-ui/icons";

const fields = {
  given_name: "名",
  family_name: "姓",
  given_name_kana: "めい",
  family_name_kana: "せい",
  area: "地域",
  attribute: "属性",
  title: "タイトル",
  description: "詳細",
  id: "ID",
  email: "メールアドレス",
  stripe_id: "Stripe ID",
  subscription_id: "StripeサブスクID",
  avatar: "プロフィール画像",
  introduction: "自己紹介",
  gender: "性別",
  birthdate: "誕生日",
  last_viewed_lesson_id: "最後に視聴した講義",
  subscribed: "登録済み",
  setup: "プロフィール設定済み",
  thumbnail: "サムネイル画像",
  topic: "トピック",
  problems: "クイズ",
  question: "問題",
  correct: "正解番号",
  choice: "選択肢",
  youtube: "YouTube ID",
  name: "名称",
  full_name: "名前",
  full_name_kana: "なまえ",
  exeternal_link: "詳細リンク",
  external_link: "詳細リンク",
  category: "学部",
  created_at: "作成日時",
  updated_at: "更新日時",
  image: "画像",
  datetime: "日時",
  facebook_url: "facebookリンク",
  google_map_url: "GoogleMapリンク",
  detail_url: "詳細リンク",
  highlighted_events: "注目のイベント",
  highlighted_live_streams: "注目のライブ講義",
  highlighted_voice_medias: "注目の音声講義",
};

const japaneseDomainMessages = {
  resources: {
    users: {
      name: "ユーザー",
      fields: fields,
    },
    accounts: {
      name: "アカウント",
      fields: fields,
    },
    profiles: {
      name: "プロフィール",
      fields: fields,
    },
    categories: {
      name: "学部",
      fields: fields,
    },
    subjects: {
      name: "学科",
      fields: fields,
    },
    lessons: {
      name: "講義",
      fields: fields,
    },
    voice_media: {
      name: "音声講義",
      fields: fields,
    },
    topics: {
      name: "トピック",
      fields: fields,
    },
    instructors: {
      name: "講師",
      fields: fields,
    },
    announcements: {
      name: "新着情報",
      fields: fields,
    },
    news: {
      name: "ニュース",
      fields: fields,
    },
    events: {
      name: "イベント",
      fields: fields,
    },
    live_stream_events: {
      name: "ライブ講義",
      fields: fields,
    },
    admin_controls: {
      name: "管理者用",
      fields: fields,
    },
  },
};

const messages = {
  ja: {
    ...japaneseMessages,
    ...japaneseDomainMessages,
  },
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "ja");

const options = {
  logging: true,
  rootRef: "/",
  renameMetaFields: {
    created_at: "created_at",
    created_by: "created_by",
    updated_at: "updated_at",
    updated_by: "updated_by",
  },
};
const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

class App extends React.Component {
  render() {
    return (
      <Admin
        i18nProvider={i18nProvider}
        loginPage={CustomLoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <Resource
          name="accounts"
          list={AccountList}
          show={AccountShow}
          edit={AccountEdit}
          icon={Person}
        />
        <Resource
          name="profiles"
          list={ProfileList}
          show={ProfileShow}
          edit={ProfileEdit}
        />
        <Resource
          name="users"
          list={UserList}
          show={UserShow}
          edit={UserEdit}
          icon={People}
        />
        <Resource
          name="categories"
          list={CategoryList}
          create={CategoryCreate}
          show={CategoryShow}
          edit={CategoryEdit}
          icon={Folder}
        />
        <Resource
          name="subjects"
          list={SubjectList}
          create={SubjectCreate}
          show={SubjectShow}
          edit={SubjectEdit}
          icon={Class}
        />
        <Resource
          name="lessons"
          list={LessonList}
          create={LessonCreate}
          show={LessonShow}
          edit={LessonEdit}
          icon={Assignment}
        />
        <Resource
          name="voice_media"
          list={VoiceMediaList}
          create={VoiceMediaCreate}
          show={VoiceMediaShow}
          edit={VoiceMediaEdit}
          icon={Assignment}
        />
        <Resource
          name="topics"
          list={TopicList}
          create={TopicCreate}
          show={TopicShow}
          edit={TopicEdit}
          icon={Palette}
        />
        <Resource
          name="instructors"
          list={InstrcutorList}
          create={InstrcutorCreate}
          show={InstrcutorShow}
          edit={InstrcutorEdit}
        />
        <Resource
          name="announcements"
          list={AnnouncementList}
          show={AnnouncementShow}
          edit={AnnouncementEdit}
          create={AnnouncementCreate}
        />
        {/*<Resource
          name="subject_subscription"
          list={SubjectSubscriptionList}
          create={SubjectSubscriptionCreate}
          show={SubjectSubscriptionShow}
          edit={SubjectSubscriptionEdit}
          icon={LiveTv}
        />*/}
        <Resource
          name="news"
          list={NewsList}
          create={NewsCreate}
          show={NewsShow}
          edit={NewsEdit}
          icon={DynamicFeed}
        />
        <Resource
          name="events"
          list={EventList}
          create={EventCreate}
          show={EventShow}
          edit={EventEdit}
          icon={Announcement}
        />
        <Resource
          name="live_stream_events"
          list={LiveStreamEventList}
          create={LiveStreamEventCreate}
          show={LiveStreamEventShow}
          edit={LiveStreamEventEdit}
          icon={LiveTv}
        />
        <Resource
          name="admin_controls"
          list={AdminControlsList}
          show={AdminControlsShow}
          edit={AdminControlsEdit}
        />
      </Admin>
    );
  }
}

export default App;
