// in src/users.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
} from "react-admin";
import { user } from "./models";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="family_name" alwaysOn />
  </Filter>
);

export const UserList = (props) => (
  <List {...props} filters={<UserFilter />}>
    <Datagrid>
      {user.list.map((item) => {
        return user.attributes[item]["read"];
      })}
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const UserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      {user.show.map((item) => {
        return user.attributes[item].read;
      })}
    </SimpleShowLayout>
  </Show>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {user.create.map((item) => {
        return user.attributes[item].write;
      })}
    </SimpleForm>
  </Create>
);

export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {user.edit.map((item) => {
        return user.attributes[item].write;
      })}
    </SimpleForm>
  </Edit>
);
