// in src/topics.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
} from "react-admin";
import { topic } from "./models";

const TopicFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

export const TopicList = (props) => (
  <List {...props} filters={<TopicFilter />}>
    <Datagrid>
      {topic.list.map((item) => {
        return topic.attributes[item]["read"];
      })}
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const TopicShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      {topic.show.map((item) => {
        return topic.attributes[item].read;
      })}
    </SimpleShowLayout>
  </Show>
);

export const TopicCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {topic.create.map((item) => {
        return topic.attributes[item].write;
      })}
    </SimpleForm>
  </Create>
);

export const TopicEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {topic.edit.map((item) => {
        return topic.attributes[item].write;
      })}
    </SimpleForm>
  </Edit>
);
