import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
} from "react-admin";
import { news } from "./models";

const NewsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="title" alwaysOn />
  </Filter>
);

export const NewsList = (props) => (
  <List {...props} filters={<NewsFilter />}>
    <Datagrid>
      {news.list.map((item) => {
        return news.attributes[item]["read"];
      })}
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const NewsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      {news.show.map((item) => {
        return news.attributes[item].read;
      })}
    </SimpleShowLayout>
  </Show>
);

export const NewsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {news.create.map((item) => {
        return news.attributes[item].write;
      })}
    </SimpleForm>
  </Create>
);

export const NewsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {news.edit.map((item) => {
        return news.attributes[item].write;
      })}
    </SimpleForm>
  </Edit>
);
