import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
} from "react-admin";
import { event } from "./models";

const EventFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="title" alwaysOn />
  </Filter>
);

export const EventList = (props) => (
  <List {...props} filters={<EventFilter />}>
    <Datagrid>
      {event.list.map((item) => {
        return event.attributes[item]["read"];
      })}
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const EventShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      {event.show.map((item) => {
        return event.attributes[item].read;
      })}
    </SimpleShowLayout>
  </Show>
);

export const EventCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {event.create.map((item) => {
        return event.attributes[item].write;
      })}
    </SimpleForm>
  </Create>
);

export const EventEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {event.edit.map((item) => {
        return event.attributes[item].write;
      })}
    </SimpleForm>
  </Edit>
);
