export const firebaseConfigDev = {
  apiKey: "AIzaSyBd5GB3hMI8XhRRe4hSRedq_t1il69VmsU",
  authDomain: "encoln-dev.firebaseapp.com",
  projectId: "encoln-dev",
  storageBucket: "encoln-dev.appspot.com",
  messagingSenderId: "696269391137",
  appId: "1:696269391137:web:1d2f530293fddf929daa60",
  measurementId: "G-4DQP4RF5Y3",
};

export const firebaseConfigProd = {
  apiKey: "AIzaSyA0uYMMTT8FVjJcrZnkRsugtLyarCfawK8",
  authDomain: "encoln-prod.firebaseapp.com",
  projectId: "encoln-prod",
  storageBucket: "encoln-prod.appspot.com",
  messagingSenderId: "217243409407",
  appId: "1:217243409407:web:b12f504088434f1c2165ca",
  measurementId: "G-8LHNWK88PP",
};


export const firebaseConfig =
  process.env.REACT_APP_DEVEL === "true"
    ? firebaseConfigDev
    : firebaseConfigProd;
